<template>
    <div class="content-box">
        <b-form @submit.prevent="onSubmit">
            <b-row>
                <b-col cols-lg="6">
                    <b-form-group
                        :class="{ row, error: v$.typeSelect.$errors.length }"
                        :label="$t('forms.usageType')"
                        label-for="type"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <v-select
                            id="type"
                            v-model="typeSelect"
                            :options="types"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.typeSelect.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                    <b-form-group
                        :class="{ row, error: v$.stock.quantity.$errors.length }"
                        :label="$t('forms.usageQuantity')"
                        label-for="quantity"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.quantity"
                            id="quantity"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.quantity.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>

                    <b-form-group
                        :class="{ row, error: v$.stock.remark.$errors.length }"
                        :label="$t('forms.mark')"
                        label-for="mark"
                        label-cols-md="4"
                        content-cols-lg="8"
                    >
                        <b-form-input
                            v-model="stock.remark"
                            id="mark"
                        />
                        <div
                            class="input-errors"
                            v-for="error of v$.stock.remark.$errors"
                            :key="error.$uid"
                        >
                            <small class="text-danger">{{ error.$message }}</small>
                        </div>
                    </b-form-group>
                </b-col>
                <b-col cols-lg="6"></b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import {numeric, required} from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import '@vuepic/vue-datepicker/dist/main.css'
import vSelect from "vue-select";

export default {
    components: {
        vSelect
    },
    props: {
        stockObject: {Object, required: true},
        action: {type: String, default: null},
        stockItem: {type: String, required: true},
    },
    mixins: [ResourceUtils, ListUtils],
    created() {
        this.getEnumList(["used_item_status"], this.types);
    },
    setup: () => ({v$: useVuelidate()}),
    validations() {
        return {
            typeSelect: {required},
            stock: {
                quantity: {required, numeric},
                remark: {required}
            },
        };
    },
    data() {
        return {
            stock: this.stockObject,
            types: [],
            typeSelect: null,
        };
    },
    watch: {
        action() {
            this.onSubmit();
        },
    },
    methods: {
        async onSubmit() {
            if (this.action !== "updateUsage") {
                return
            }
            const isValid = await this.v$.$validate();
            if (isValid) {
                if (this.typeSelect) {
                    this.stock.type = this.typeSelect.id
                }
                // this.stock.quantity = this.stock.quantity ? parseFloat(this.stock.quantity) : this.stock.quantity
                this.stock.stockItem = this.stockItem
                this.$emit("clearAction");
                this.create(this.$UsedItems, this.stock, this.$t("messages.stockItemsUpdated"), null, null, this.error);
            } else {
                this.$emit("clearAction");
            }
        },
        success() {
            this.$router.push(`/stock-items`);
        },
        error() {
            this.$emit("clearAction");
        },
    },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

